<template>
  <div>
    <div class="cont-main" v-loading="loadings">
      <!-- 输入框部分 -->
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        label-width="110px"
        label-position="right"
        :inline="true"
        class="demo-ruleForm col-333 lable-110"
      >
        <el-form-item label="称重方式：">
          <el-select
            v-model="ruleForm.dict_after_weight_way"
            placeholder="请选择"
          >
            <el-option
              v-for="item in dictList[178000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="透后体重：">
          <el-input
            @input="weightInput"
            v-model="ruleForm.after_weight"
            placeholder="请输入"
          >
            <template #append>kg</template>
          </el-input>
        </el-form-item>
        <el-form-item label="实际透后体重">
          <el-input
            disabled
            v-model="ruleForm.after_clean_weight"
            placeholder="请输入"
          >
            <template #append>kg</template>
          </el-input>
        </el-form-item>
        <el-form-item label="透析前后体重差">
          <el-input
            disabled
            v-model="ruleForm.befor_clean_weight"
            placeholder="请输入"
          >
            <template #append>kg</template>
          </el-input>
        </el-form-item>
        <el-form-item label="差：">
          <el-input disabled v-model="ruleForm.differ" placeholder="请输入">
            <template #append>kg</template>
          </el-input>
        </el-form-item>
        <el-form-item class="lstwo" label="实际时长：">
          <el-input v-model="ruleForm.actual_touxi_hour" placeholder="请输入">
            <template #append>时</template>
          </el-input>
          <el-input v-model="ruleForm.actual_touxi_min" placeholder="请输入">
            <template #append>分</template>
          </el-input>
        </el-form-item>
        <el-form-item label="机显脱水：">
          <el-input
            v-model="ruleForm.screen_actual_warter"
            placeholder="请输入"
          >
            <template #append>kg</template>
          </el-input>
        </el-form-item>
        <el-form-item label="总输液量：">
          <el-input v-model="ruleForm.total_flush_water" placeholder="请输入">
            <template #append>ml</template>
          </el-input>
        </el-form-item>
        <el-form-item label="收缩压：">
          <el-input
            v-model="ruleForm.after_systolic_pressure"
            placeholder="请输入"
          >
            <template #append>mmHg</template>
          </el-input>
        </el-form-item>
        <el-form-item label="舒张压：">
          <el-input
            v-model="ruleForm.after_diastolic_pressure"
            placeholder="请输入"
          >
            <template #append>mmHg</template>
          </el-input>
        </el-form-item>
        <el-form-item label="脉搏：">
          <el-input v-model="ruleForm.after_pulse" placeholder="请输入">
            <template #append>次/分</template>
          </el-input>
        </el-form-item>
        <el-form-item label="测量部位：">
          <el-select
            v-model="ruleForm.dict_after_pressure_way"
            placeholder="请选择"
          >
            <el-option
              v-for="item in dictList[179000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="体温：">
          <el-input v-model="ruleForm.after_temperature" placeholder="请输入">
            <template #append>℃</template>
          </el-input>
        </el-form-item>
        <el-form-item label="Kt/V：">
          <el-input v-model="ruleForm.ktv" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>

      <div class="title flex-start">
        <div class="jniop"></div>
        <div class="juliss">透后评估</div>
      </div>

      <el-form
        :model="ruleForm"
        label-width="110px"
        label-position="right"
        :inline="true"
        class="demo-ruleForm col-333 lable-110"
      >
        <el-form-item label="脱管：">
          <el-select v-model="ruleForm.tuoguan_flag" placeholder="请选择">
            <el-option
              v-for="item in dictList[193000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="坠床：">
          <el-select v-model="ruleForm.zhuichuang_flag" placeholder="请选择">
            <el-option
              v-for="item in dictList[195000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="动脉壶凝血：">
          <el-select v-model="ruleForm.dict_dong_mai_hu" placeholder="请选择">
            <el-option
              v-for="item in dictList[202000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="静脉壶凝血：">
          <el-select v-model="ruleForm.dict_jing_mai_hu" placeholder="请选择">
            <el-option
              v-for="item in dictList[202000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="失血量："
          v-if="
            !(
              ruleForm.dict_dong_mai_hu == '202110000' &&
              ruleForm.dict_jing_mai_hu == '202110000'
            )
          "
        >
          <el-input v-model="ruleForm.shi_xue_liang" placeholder="请输入">
            <template #append>kg</template>
          </el-input>
        </el-form-item>
        <el-form-item label="透中摄入：">
          <el-select v-model="ruleForm.eat_flag" placeholder="请选择">
            <el-option
              v-for="item in dictList[195000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="按计划完成透析">
          <el-select v-model="ruleForm.success_end_flag" placeholder="请选择">
            <el-option
              v-for="item in dictList[195000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="透析器凝血：">
          <el-select
            v-model="ruleForm.dict_tou_xi_qi_ning_xue"
            placeholder="请选择"
          >
            <el-option
              v-for="item in dictList[202000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="title flex-start" v-if="ruleForm.type === 3">
        <div class="jniop"></div>
        <div class="juliss">内瘘评估</div>
      </div>
      <el-form
        ref="ruleForm1Ref"
        :model="ruleForm"
        :rules="Rule.SUMMARY"
        label-width="110px"
        label-position="right"
        :inline="true"
        class="demo-ruleForm col-333 lable-110"
        v-if="ruleForm.type === 3"
      >
        <el-form-item label="震颤：">
          <el-select v-model="ruleForm.tremor" placeholder="请选择">
            <el-option
              v-for="item in dictList[200000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="杂音：">
          <el-select v-model="ruleForm.zayin" placeholder="请选择">
            <el-option
              v-for="item in dictList[228000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="静脉穿刺次数" prop="jingmai_num">
          <el-select v-model="ruleForm.jingmai_num" placeholder="请选择">
            <el-option
              v-for="item in dictList[630000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="动脉穿刺次数" prop="dongmai_num">
          <el-select v-model="ruleForm.dongmai_num" placeholder="请选择">
            <el-option
              v-for="item in dictList[630000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内瘘针滑脱" prop="internal">
          <el-select v-model="ruleForm.internal" placeholder="请选择">
            <el-option
              v-for="item in dictList[195000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="addr" label="其他：">
          <el-input
            v-model="ruleForm.other"
            type="textarea"
            :rows="2"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="title flex-start" v-if="ruleForm.type === 2">
        <div class="jniop"></div>
        <div class="juliss">导管评估</div>
      </div>
      <el-form
        :model="ruleForm"
        label-width="110px"
        label-position="right"
        :inline="true"
        class="demo-ruleForm col-333 lable-110"
        v-if="ruleForm.type === 2"
      >
        <el-form-item label="封管用药：">
          <el-select v-model="ruleForm.pipe_drug" placeholder="请选择">
            <el-option
              v-for="item in dictList[204000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="动脉端">
          <el-select v-model="ruleForm.dongmai" placeholder="请选择">
            <el-option
              v-for="item in dictList[196000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="静脉端">
          <el-select v-model="ruleForm.jingmai" placeholder="请选择">
            <el-option
              v-for="item in dictList[197000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="title flex-start">
        <div class="jniop"></div>
        <div class="juliss">透后小结</div>
      </div>
      <el-form
        :model="ruleForm"
        label-width="110px"
        label-position="right"
        :inline="true"
        class="demo-ruleForm col-333 lable-110"
      >
        <el-form-item class="addr but" label="小结：">
          <el-input
            v-model="ruleForm.summary"
            type="textarea"
            :rows="3"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <div class="templateBut">
          <el-button
            @click="generateSummary"
            :loading="saveLoading"
            class="color-determine"
            type="primary"
            >生成小结</el-button
          >
          <el-button
            @click="openTemplate"
            class="color-determine tip"
            type="primary"
            >使用模板</el-button
          >
        </div>
      </el-form>
      <div class="title flex-start">
        <div class="jniop"></div>
        <div class="juliss">签名</div>
      </div>
      <el-form
        ref="ruleForm2Ref"
        :model="ruleForm"
        :rules="Rule.DIALYSIS"
        label-width="110px"
        label-position="right"
        :inline="true"
        class="demo-ruleForm col-333 lable-110"
      >
        <el-form-item label="回血者：">
          <el-select v-model="ruleForm.huixue_id" placeholder="请选择">
            <el-option
              v-for="item in userNameList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡视者：">
          <el-select v-model="ruleForm.xunshi_id" placeholder="请选择">
            <el-option
              v-for="item in userNameList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="责任护士：">
          <el-select v-model="ruleForm.zeren_id" placeholder="请选择">
            <el-option
              v-for="item in userNameList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查对护士：">
          <el-select v-model="ruleForm.chadui_id" placeholder="请选择">
            <el-option
              v-for="item in userNameList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="擦机护士：">
          <el-select v-model="ruleForm.caji_id" placeholder="请选择">
            <el-option
              v-for="item in userNameList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="医生签名：">
          <el-select v-model="ruleForm.doctor_id" placeholder="请选择">
            <el-option
              v-for="item in userNameList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <!-- 保存按钮 -->
    <div class="fiall">
      <div
        class="warings"
        v-if="searchData.record_id != 0"
        @click="disinfectRecordOpen"
      >
        <template v-if="machine_status == 1">已消毒</template>
        <template v-else>
          消毒
          <br />记录
        </template>
      </div>
      <!-- <div class="warings" @click="estimateSave" style="background: #0057bb">
        保存
      </div> -->
    </div>

    <!-- 使用模板弹窗 -->
    <BaseDialog
      title="透后小结模板选择"
      :isshow="bodyvlus"
      @handleShow="
        (val) => {
          bodyvlus = val
        }
      "
      width="1000px"
    >
      <!-- 表格 -->
      <div style="margin-top: 20px">
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          size="small"
          border
          height="350"
        >
          <el-table-column width="200" prop="tem_name" label="模板名称" />
          <el-table-column prop="tem_show" label="模板内容" />
          <el-table-column fixed="right" label="操作" width="100">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                @click="useTemplate(scope.row)"
                >使用</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页 -->
      <div class="pagination-box">
        <el-pagination
          v-model:currentPage="pageData.currentPage"
          v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="bodyvlus = false"
            >取消</el-button
          >
        </div>
      </template>
    </BaseDialog>
    <BaseDialog
      title="透后小结模板选择"
      :isshow="bodyvlus"
      @handleShow="
        (val) => {
          bodyvlus = val
        }
      "
      width="1000px"
    >
      <!-- 表格 -->
      <div style="margin-top: 20px">
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          size="small"
          border
          height="350"
        >
          <el-table-column width="200" prop="tem_name" label="模板名称" />
          <el-table-column prop="tem_show" label="模板内容" />
          <el-table-column fixed="right" label="操作" width="100">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                @click="useTemplate(scope.row)"
                >使用</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="bodyvlus = false"
            >取消</el-button
          >
        </div>
      </template>
    </BaseDialog>
    <disinfectRecord
      v-if="isViewDialog"
      ref="disinfectRecordRef"
      @refreshDataList="refreshData"
    ></disinfectRecord>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, nextTick, watch } from 'vue'
import Rule from '@/utils/rule'
import service from '@/utils/request'
import { ElMessage, ElMessageBox } from 'element-plus'
import { gitDictList } from '@/utils/tool'
import BaseDialog from '@/components/Dialog/index.vue'
import disinfectRecord from './disinfectRecord.vue'
export default {
  components: {
    BaseDialog,
    disinfectRecord,
  },
  props: ['patientData'],
  emits: ['stepsUpdate'],
  setup(props, ctx) {
    const userInfo = JSON.parse(localStorage.getItem('ms_userData'))
    const state = reactive({
      dictList: {},
      ruleForm: {},
      machine_status: '',
      loadings: false,
      Rule: Rule,
      ruleForm1Ref: null,
      patientId: '14',
      userNameList: [
        {
          name: localStorage.getItem('ms_username'),
          code: userInfo.uid,
          id: userInfo.uid,
        },
      ],
      saveLoading: false,
      searchDatas: {},
      tableLoading: false,
      bodyvlus: false,
      tableData: [],
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      isViewDialog: false,
      disinfectRecordRef: null,
      searchData: {
        patient_id: 0,
        record_id: 0,
      },
      autograph: {
        huixue_id: '',
        xunshi_id: '',
        zeren_id: '',
        chadui_id: '',
        caji_id: '',
        doctor_id: '',
      },
    })

    watch(
      () => props.patientData.record_id,
      (val) => {
        if (val) {
          state.searchData = {
            patient_id: props.patientData.patient_id,
            record_id: props.patientData.record_id,
          }
        } else {
          state.searchData = {
            patient_id: 0,
            record_id: 0,
          }
        }
        getDataList()
      }
    )

    onMounted(() => {
      if (props.patientData.patient_id) {
        state.searchData = {
          patient_id: props.patientData.patient_id,
          record_id: props.patientData.record_id,
        }
      } else {
        state.searchData = {
          patient_id: 0,
          record_id: 0,
        }
      }
      getDataList()
      getDict()
    })
    const refreshData = () => {
      getDataList()
      ctx.emit('stepsUpdate')
    }
    const getDataList = async () => {
      state.loadings = true
      let res = await service.post('api/estimate/summaryList', state.searchData)
      state.loadings = false
      if (res.code === 0) {
        if (!Array.isArray(res.data.array)) {
          state.ruleForm = res.data.array
          state.machine_status = res.data.machine_status
          // Afterthoroughly()
          getAutograph(state.ruleForm)
        }
      }
    }
    const getAutograph = (item) => {
      if (item.huixue_id != userInfo.uid) {
        state.autograph.huixue_id = JSON.parse(JSON.stringify(item)).huixue_id
        item.huixue_id = item.huixue_id_name
      }
      if (item.zeren_id != userInfo.uid) {
        state.autograph.zeren_id = JSON.parse(JSON.stringify(item)).zeren_id
        item.zeren_id = item.zeren_id_name
      }
      if (item.xunshi_id != userInfo.uid) {
        state.autograph.xunshi_id = JSON.parse(JSON.stringify(item)).xunshi_id
        item.xunshi_id = item.xunshi_id_name
      }
      if (item.chadui_id != userInfo.uid) {
        state.autograph.chadui_id = JSON.parse(JSON.stringify(item)).chadui_id
        item.chadui_id = item.chadui_id_name
      }
      if (item.caji_id != userInfo.uid) {
        state.autograph.caji_id = JSON.parse(JSON.stringify(item)).caji_id
        item.caji_id = item.caji_id_name
      }
      if (item.doctor_id != userInfo.uid) {
        state.autograph.doctor_id = JSON.parse(JSON.stringify(item)).doctor_id
        item.doctor_id = item.doctor_id_name
      }
    }
    const getSaveAutograph = () => {
      if (state.ruleForm.huixue_id != userInfo.uid) {
        state.ruleForm.huixue_id = state.autograph.huixue_id
      }
      if (state.ruleForm.zeren_id != userInfo.uid) {
        state.ruleForm.zeren_id = state.autograph.zeren_id
      }
      if (state.ruleForm.xunshi_id != userInfo.uid) {
        state.ruleForm.xunshi_id = state.autograph.xunshi_id
      }
      if (state.ruleForm.chadui_id != userInfo.uid) {
        state.ruleForm.chadui_id = state.autograph.chadui_id
      }
      if (state.ruleForm.caji_id != userInfo.uid) {
        state.ruleForm.caji_id = state.autograph.caji_id
      }
      if (state.ruleForm.doctor_id != userInfo.uid) {
        state.ruleForm.doctor_id = state.autograph.doctor_id
      }
    }
    const getDict = async () => {
      state.dictList = await gitDictList([
        '178000000',
        '179000000',
        '193000000',
        '195000000',
        '196000000',
        '197000000',
        '202000000',
        '200000000',
        '228000000',
        '204000000',
        '630000000',
      ])
    }
    const ruleForm = (formEl) => {
      let flag = false
      if (!state[formEl]) return true
      state[formEl].validate((valid) => {
        if (valid) {
          flag = true
        } else {
          flag = false
        }
      })
      return flag
    }
    const weightInput = (val) => {
      val = val.replace(/[^\d.]/g, '')
      state.ruleForm.after_weight = state.ruleForm.after_weight.replace(
        /[^\d.]/g,
        ''
      )
      if (state.ruleForm.befor_weight <= 0 || !val) {
        state.ruleForm.after_clean_weight = ''
        state.ruleForm.befor_clean_weight = ''
        state.ruleForm.differ = ''
        return
      }
      state.ruleForm.after_clean_weight = (
        val - state.ruleForm.add_weight
      ).toFixed(2)
      state.ruleForm.befor_clean_weight = (
        state.ruleForm.befor_weight - state.ruleForm.after_clean_weight
      ).toFixed(2)
      state.ruleForm.differ = (
        state.ruleForm.dry_weight - state.ruleForm.after_clean_weight
      ).toFixed(2)
    }
    const estimateSave = async () => {
      if (!state.searchData.patient_id) {
        ElMessageBox.alert('请先选择患者', '提示', {
          confirmButtonText: '确认',
        })
        return
      }
      if (!state.searchData.record_id) {
        ElMessageBox.alert(
          '当前患者的透析治疗记录还没保存，不能保存透后小结！',
          '提示',
          {
            confirmButtonText: '确认',
          }
        )
        return
      }
      const rule1 = ruleForm('ruleForm1Ref')
      if (rule1) {
        state.ruleForm.patient_id = state.searchData.patient_id
        state.ruleForm.record_id = state.searchData.record_id
        getSaveAutograph()
        let res = await service.post('api/estimate/summary', state.ruleForm)
        if (res.code === 0) {
          ElMessage.success(res.msg)
          ctx.emit('stepsUpdate')
          getAutograph(state.ruleForm)
        }
      }
    }
    // 生成小结
    const generateSummary = async () => {
      if (!state.searchData.record_id && state.searchData.record_id === 0) {
        ElMessage({
          message: '请先添加透析记录',
          type: 'warning',
        })
        return
      }
      state.ruleForm.patient_id = state.searchData.patient_id
      state.ruleForm.record_id = state.searchData.record_id
      state.saveLoading = true
      let res = await service.post('api/estimate/createSummary', state.ruleForm)
      state.saveLoading = false
      if (res.code === 0) {
        ElMessage.success(res.msg)
        if (state.ruleForm.summary) {
          state.ruleForm.summary += '\n' + res.data.tem_show
        } else {
          state.ruleForm.summary += res.data.tem_show
        }
      }
    }

    const openTemplate = () => {
      if (!state.searchData.record_id && state.searchData.record_id === 0) {
        ElMessage({
          message: '请先添加透析记录',
          type: 'warning',
        })
        return
      }
      state.bodyvlus = true
      getSummaryTemplate()
    }
    // 模板列表
    const getSummaryTemplate = async () => {
      state.searchDatas.current = state.pageData.currentPage
      state.searchDatas.size = state.pageData.pageSize
      state.tableLoading = true
      let res = await service.post(
        'api/estimate/summaryTemplate',
        state.searchDatas
      )
      state.tableLoading = false
      if (res.code === 0) {
        state.tableData = res.data.template
        state.pageData.totalSum = res.data.total
      }
    }
    const useTemplate = (item) => {
      state.ruleForm.summary += '\n' + item.tem_show
      state.bodyvlus = false
    }
    const disinfectRecordOpen = () => {
      state.isViewDialog = true
      nextTick(() => {
        state.disinfectRecordRef.initData(state.searchData)
      })
    }
    const handleSizeChange = (val) => {
      state.pageData.pageSize = val
      getSummaryTemplate()
    }
    const handleCurrentChange = (val) => {
      state.pageData.currentPage = val
      getSummaryTemplate()
    }

    return {
      ...toRefs(state),
      estimateSave,
      generateSummary,
      openTemplate,
      useTemplate,
      handleSizeChange,
      handleCurrentChange,
      disinfectRecordOpen,
      getDataList,
      refreshData,
      weightInput,
    }
  },
}
</script>

<style scoped lang="scss">
.cont-main {
  flex: 1;
  // max-height: 600px;
  // overflow-y: auto;
  // overflow-x: hidden;
  .el-form {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 20px;
  }
  .el-form-item {
    width: 30%;
    margin-right: 5%;
    &.addr {
      width: 100%;
      margin-right: 0;
      &.but {
        width: calc(100% - 100px);
      }
    }
  }
  .templateBut {
    width: 88px;
    margin-left: 12px;
    display: inline-block;
    position: relative;
    top: 7px;
    .tip {
      margin-left: 0;
      margin-top: 6px;
    }
  }
  .el-form-item:nth-child(3n + 3) {
    margin-right: 0;
  }
  .userItem {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 30px;
    .user-tit {
      font-size: 24px;
      font-family: 'Source Han Sans CN-Medium';
      color: #333333;
      border-left: 6px solid #3166ae;
      padding: 0 18px;
      margin-bottom: 40px;
    }
  }
}
.title {
  margin-bottom: 20px;
  .jniop {
    width: 6px;
    height: 18px;
    background: #3166ae;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    margin-top: 4px;
    margin-right: 10px;
  }
  .juliss {
    height: 18px;
    font-size: 18px;
    color: #333333;
    margin-left: 10px;
  }
}
.fiall {
  display: flex;
  position: fixed;
  bottom: 5%;
  right: 5%;
}
.warings {
  width: 60px;
  height: 60px;
  background-color: #13c2c2;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 14px;
  margin: 0 20px;
  cursor: pointer;
}
</style>
